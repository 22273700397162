import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import LayoutComponent from "../shared/LayoutComponent";
import SidebarComponent from "../shared/SidebarComponent";
import moment from "moment";
import { getAuthToken } from "../utils/token.utils";

const Plan1 = () => {
  const [user, setuser] = useState("");
  const [suscription, setsuscription] = useState("");
  const [customerpotalurl, SetCustomerpotalurl] = useState("");
  const token = getAuthToken();

  useEffect(() => {
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/retrieve-user-subscription",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        // console.log(response.data.body.data);
        setuser(response.data.body.data);
        setsuscription(response.data.body.data.product);
      })
      .catch((error) => {
        // console.log(error);
      });
    // window.localStorage.setItem("profile", JSON.stringify(users));
  }, []);
  const submitHandler = async (event) => {
    event.preventDefault();
    // const return_url = process.env.STRIPE_RETURN_URL;
    // const return_url = "https://d3ouejn2p8m8vy.cloudfront.net/home";
    const return_url = "http://localhost:3000/";
    // console.log(return_url);
    // setIsLoading(true);
    await axios
      .post(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/stripe-customer-portal",
        { return_url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        console.log(res);
        SetCustomerpotalurl(res.data.body.data?.session_url);
        window.location.replace(res.data.body.data?.session_url);
      });
  };

  return (
    <>
      {/* Dashboard */}
      <LayoutComponent>
        <div className="main-container d-flex">
          <SidebarComponent></SidebarComponent>
          <div className="content py-5 px-5">
            <div className="container">
              <h4 className="card-title align-items-start  flex-column card-label fw-bold fs-3 pb-3">
                Your Subscription Detail
              </h4>
              <h4 className="pb-1">Subscription type: {suscription.name}</h4>
              <p>
                ${suscription?.price?.unit_amount_decimal}/{" "}
                {suscription?.price?.recurring?.interval}
              </p>
              <p>
                Suscription Start Date:
                {moment(user.start_datetime_utc).format("M/D/YYYY")}|
                {moment(user.start_datetime_utc).format("ddd h:mma")}
              </p>
              <p>
                Suscription End Date:
                {moment(user.end_datetime_utc).format("M/D/YYYY")}|
                {moment(user.end_datetime_utc).format("ddd h:mma")}
              </p>
              <p>{suscription.description}</p>
              {/* <span>
                Your plan renews on{" "}
                {moment(user.start_datetime_utc).format("M/D/YYYY")}
              </span> */}
              <br />
              {/* <a href={customerpotalurl}> */}
              <button
                className="btn btn-md btn-primary flex items-center mt-1"
                onClick={submitHandler}
              >
                View More
              </button>
              {/* </a> */}
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};

export default Plan1;
