import { createContext, useReducer, useContext } from 'react';
const initialState = {
	authUser: '',
};

const AuthStateContext = createContext(undefined);
const AuthStateUpdaterContext = createContext(undefined);

const stateReducer = (state, payload) => {
	localStorage.setItem('updatedUser', JSON.stringify(payload))
	return {
		...state,
		authUser: payload,
	};
};

const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(stateReducer, initialState);

	return (
		<AuthStateContext.Provider value={{ state }}>
			<AuthStateUpdaterContext.Provider value={{ dispatch }}>
				{children}
			</AuthStateUpdaterContext.Provider>
		</AuthStateContext.Provider>
	);
};

const useAuthStateContext = () => {
	const context = useContext(AuthStateContext);
	if (context) {
		return context;
	}

	throw new Error(`useAuthStateContext must be used within a AuthProvider`);
};

const useAuthStateUpdaterContext = () => {
	const context = useContext(AuthStateUpdaterContext);

	if (context) {
		return context;
	}

	throw new Error(`useAuthStateUpdaterContext must be used within a AuthProvider`);
};

export { AuthProvider, useAuthStateContext, useAuthStateUpdaterContext };
