import React, { useEffect, useState } from "react";
import { getStoriesDescripton } from "../services/stories/stories.service";
import LayoutComponent from "../shared/LayoutComponent";
import FooterComponent from "../shared/FooterComponent";
import { NavLink, useLocation } from "react-router-dom";

const ViewUserStories = () => {
  const [csvData, setcsvData] = useState([]);
  const [downloadCsv, setdownloadCsv] = useState([]);
  const { state } = useLocation();
  const { title } = state;

  // const { title } = useParams();
  const viewDescription = (title) => {
    getStoriesDescripton({ title }).then((res) => {
      const response = res?.data?.body?.data?.csv_data;
      setcsvData(response);
      setdownloadCsv(res?.data?.body?.data);
    });
  };
  useEffect(() => {
    viewDescription(title);
  }, [title]);
  return (
    <>
      <LayoutComponent>
        <div className="main-view">
          <div className="container">
            <div className="download-view mt-5 mb-4">
              <a href={downloadCsv.download_url} class="btn btn-primary me-3">
                Download <i class="bi bi-download"></i>
              </a>
              {/* <NavLink to="/upload-jira-azure">
                {" "}
                <button className="btn btn-outline">
                  Upload to Jira/Azure Devops
                </button>
              </NavLink> */}
            </div>

            <div className="table-responsive view-table mb-5">
              <table class="table table-bordered">
                <thead class="table-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" className="w-40">
                      User Stories
                    </th>
                    <th scope="col" className="w-60">
                      Acceptance Criteria
                    </th>
                  </tr>
                </thead>
                {csvData.map((item) => (
                  <tbody>
                    <tr>
                      <th scope="row">{item.id}</th>
                      <td>{item.user_story}</td>
                      <td>
                        <ul>
                          {item.acceptance_criteria
                            .split("-")
                            .filter(Boolean)
                            .map((sentence, index) => (
                              <li key={index}>{sentence.trim()}</li>
                            ))}
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
        <FooterComponent />
      </LayoutComponent>
    </>
  );
};

export default ViewUserStories;
