import React from "react";
import FooterComponent from "./FooterComponent";
import NavbarComponent from "./NavbarComponent";
// import SidebarComponent from "./SidebarComponent";

const LayoutComponent = ({ children }) => {
  return (
    <>
      <NavbarComponent />
      {children}
    </>
  );
};

export default LayoutComponent;
