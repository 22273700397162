import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import LayoutComponent from "../shared/LayoutComponent";
import SidebarComponent from "../shared/SidebarComponent";
import { toast } from "react-toastify";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "AgilemoveEmail",
        "template_25yycbb",
        form.current,
        "WQsAhNtK-rNnMMVLd"
      )
      .then(
        (result) => {
          toast.success("Email Sent Successful", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      {/* Dashboard */}
      <LayoutComponent>
        <div className="main-container d-flex">
          <SidebarComponent></SidebarComponent>
          <div className="content py-5 px-5">
            <div className="container">
              <h4 className="card-title align-items-start flex-column pb-4">
                <span className="card-label fw-bold fs-3 mb-1">
                  Have Some Questions?
                </span>
              </h4>
              <form ref={form} onSubmit={sendEmail}>
                <div className="profile-form w-75 w-100-mob">
                  <div className="row">
                  <div className="col-md-6 mb-3">
                      <label
                        htmlFor="formGroupExampleInput"
                        className="form-label"
                      >
                        Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        required
                        name="user_name"
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="formGroupExampleInput" class="form-label">
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        required
                        name="user_email"/>
                    </div>
                  </div>


                  <div className="mb-3">
                  <label
                        htmlFor="formGroupExampleInput"
                        className="form-label"
                      >
                        Subject*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        required
                        name="subject"
                      />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Description*
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      required
                      name="message"
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    value="Send"
                    className="btn btn-primary mt-4"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};

export default Contact;
