import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
const Confirmation = () => {
  let navigate = useNavigate();
  // const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const { state } = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = state;
    try {
      // console.log({ email, code });
      await Auth.confirmSignUp(email, code);
      navigate("/login");
    } catch (error) {
      setError(error.message);
      // console.log(error.message);
    }
  };
  return (
    <>
      <div className="d-flex wrapper">
        <div className="d-flex flex-center w-lg-50">
          <div className="w-lg-420px p-10">
            <a href="/" className="text-dark">
              {" "}
              <img src="assets/popal-lgo.svg" alt="" className="logo-auth"/>
            </a>
            <div className="login-txt pt-5">
              <h3>Verify your account</h3>

              <form className="login-frm pt-2" onSubmit={handleSubmit}>
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {error}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setError("")}
                    ></button>
                  </div>
                )}
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={state?.email}
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter your email"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Verification Code
                  </label>
                  <input
                    type="text"
                    placeholder="Code"
                    name="code"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>

                <div className="mb-3 py-2 d-grid gap-2">
                  <button type="submit" className="btn btn-primary">
                    Verify Your Account
                  </button>
                </div>
                <div className="text-center pb-5">
                  Don't have an account?
                  <a href="/signup" className="link-primary px-1">
                    Sign up
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"></div>

        <p className="footr text-gray">© Untitled UI 2077</p>
      </div>
    </>
  );
};

export default Confirmation;
