import React from "react";
import logo from "../assets/popal-lgo.svg";
import notFoundImage from "../assets/error-img.svg";
const NotFound = () => {
  return (
    <>
      <div className="not-found d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-center flex-column-fluid container text-center w-100">
          <div className="d-flex flex-column flex-center text-center">
            <div className="error-lgo w-100 mt-4">
              <a href="/">
                <img src={logo} alt="" class="logo-hom" />
              </a>
            </div>
            <img src={notFoundImage} alt="" className="error-img mt-4 mb-4" />

            <div className="error-content text-center">
              <h3>404 - Looke like you're lost.</h3>
              <p>
                We are sorry but the page you are looking for does not exist.
              </p>
              <a class="btn btn-primary mt-4" href="/">
                Back Home
              </a>
              {/* <a href='/'><i class="bi bi-arrow-left"></i> Back Home</a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
