import React from "react";
import "../css/spinner.css";
import LayoutComponent from "../shared/LayoutComponent";
import ReactLoading from "react-loading";

const LoadingSpinner = () => {
  return (
    <LayoutComponent>
      <div className="spinner-container">
        <div className="loading-spinne">
          {/* <img src="assets/giphy.gif" alt="" /> */}
          <ReactLoading
            type="spinningBubbles"
            color="#0000FF"
            height={100}
            width={50}
          />
        </div>
      </div>
    </LayoutComponent>
  );
};

export default LoadingSpinner;
