import React, { useState } from "react";

import LayoutComponent from "../shared/LayoutComponent";
import SidebarComponent from "../shared/SidebarComponent";
import { Auth } from "aws-amplify";
import jwtdecode from "jwt-decode";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getAuthToken } from "../utils/token.utils";
import { useAuthStateContext } from "../context/UserProvider";

const Account = () => {
  const { state: authUser } = useAuthStateContext();
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = getAuthToken();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const emailregix = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const ChecklastAuthuser = localStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`
  );
  const decodeToken = jwtdecode(token);
  const firstName = decodeToken["custom:first_name"];
  const lastName = decodeToken["custom:last_name"];
  const LastAuthUser = decodeToken.email;
  const changePassword = async (e) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(user, oldPassword, newPassword);

      toast.success("Password Updated Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      reset({ oldPassword: "", newPassword: "", repeatPassword: "" });

      setLoading(false);
    } catch (error) {
      setError(error.message);

      setLoading(false);
    }
  };

  return (
    <>
      {/* Dashboard */}
      <LayoutComponent>
        {/* Start::Mobile nav icon */}
        {/* <div className="container d-md-none d-block pt-3 pb-2">
          <button className="btn px-1 py-0 open-btn">
            <i class="bi bi-list"></i>
          </button>
        </div> */}
        {/* End::Mobile nav icon */}
        <div className="main-container d-flex">
          <SidebarComponent></SidebarComponent>
          <div className="content py-5 px-5">
            <div className="container">
              {emailregix.test(ChecklastAuthuser) ? (
                <div>
                  <h4 className="card-title align-items-start flex-column pb-4">
                    <span className="card-label fw-bold fs-3 mb-1">
                      Change your Password
                    </span>
                  </h4>
                  <div className="account-pass w-50 w-100-mob">
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {error}
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError("")}
                        ></button>
                      </div>
                    )}
                  </div>

                  <div className="account-pass w-50 w-100-mob">
                    <div class="fm">
                      <label for="formGroupExampleInput" class="form-label">
                        Current Password
                      </label>
                      <input
                        type="password"
                        name="oldPassword"
                        autoComplete="off"
                        className="form-control bg-transparent"
                        {...register("oldPassword", {
                          required: true,

                          onChange: (e) => setOldPassword(e.target.value),
                        })}
                      />
                      {errors.oldPassword &&
                        errors.oldPassword.type === "required" && (
                          <span className="text-danger">
                            *Password is required
                          </span>
                        )}
                    </div>

                    <div class="fm pt-4">
                      <label for="formGroupExampleInput" class="form-label">
                        New Password
                      </label>

                      <input
                        type="password"
                        name="newPassword"
                        autoComplete="off"
                        className="form-control bg-transparent"
                        {...register("newPassword", {
                          required: true,
                          minLength: 8,
                          pattern: {
                            value:
                              /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&+*])[a-zA-Z0-9!@#$%^&+*]*$/,
                          },

                          onChange: (e) => setNewPassword(e.target.value),
                        })}
                      />
                      <small className="text-danger">
                        {errors.newPassword &&
                          errors.newPassword.type === "required" && (
                            <span>*Password is required</span>
                          )}

                        {errors.newPassword &&
                          errors.newPassword.type === "minLength" && (
                            <span>
                              Password must be at least 8 characters long!
                            </span>
                          )}

                        {errors.newPassword &&
                          errors.newPassword.type === "pattern" && (
                            <ul>
                              <li> Password must have uppercase letter</li>
                              <li> Password must have lowercase letter</li>
                              <li>
                                Password must have number and special character
                              </li>
                            </ul>
                          )}
                      </small>
                    </div>

                    <div class="fm pt-4">
                      <label for="formGroupExampleInput" class="form-label">
                        Repeat Password
                      </label>
                      <input
                        type="password"
                        name="repeatPassword"
                        autoComplete="off"
                        className="form-control bg-transparent"
                        {...register("repeatPassword", {
                          validate: (match) => {
                            const password = getValues("newPassword");
                            return (
                              match === password || "Password should match"
                            );
                          },
                          onChange: (e) => setRepeatPassword(e.target.value),
                        })}
                      />
                      <small className="text-danger">
                        {errors.repeatPassword?.message}
                      </small>
                    </div>
                    <div className="mb-3 py-2 d-grid gap-2">
                      {!loading && (
                        <button
                          type="submit"
                          class="btn btn-primary mt-4"
                          onClick={handleSubmit(changePassword)}
                        >
                          Update Password
                        </button>
                      )}

                      {loading && (
                        <button
                          type="submit"
                          class="btn btn-primary mt-4"
                          onClick={handleSubmit(changePassword)}
                          disabled
                        >
                          <i className="fas fa-spinner fa-spin"></i>
                          updating
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <h4 className="card-title align-items-start flex-column pb-4 card-label fw-bold fs-3 mb-1 mt-4">
                    Detail
                  </h4>
                  <p className="fw-medium p-0 m-0">
                    {authUser?.authUser?.first_name
                      ? authUser?.authUser?.first_name
                      : firstName}{" "}
                    {authUser?.authUser?.last_name
                      ? authUser?.authUser?.last_name
                      : lastName}
                  </p>
                  <p className="text-lgt">{LastAuthUser}</p>
                  <p className="color-primary">Managed by Google</p>
                </div>
              )}

              {/* Google User */}
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};

export default Account;
