import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAuthToken } from "../utils/token.utils";

const Login = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const handleLogin = async () => {
    setLoading(true);

    try {
      await Auth.signIn(email, password);

      if (state?.pricingLogin) {
        navigate("/pricing");
      } else {
        navigate("/home");
      }
      toast.success("Login Successful", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });

      setLoading(false);
      setError("");
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleCloseError = () => setError("");

  const [token, setToken] = useState(getAuthToken())
  const [temp, setTemp] = useState(localStorage.getItem("amplify-signin-with-hostedUI"))

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'amplify-signin-with-hostedUI') {
        // Perform any necessary actions when the value changes
        console.log('localStorage value changed:', event.newValue);
        setTemp(event.newValue)
      }
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  
  useEffect(() => {
    if (temp){
      const auth_token = getAuthToken()
      setToken(auth_token)
    }
  }, [temp]);

  useEffect(() => {
    if (token){
      navigate('/home')
    } else {
      navigate('/login')
    }
  }, [token]);

  

  return (
    <>
      <div className="d-flex wrapper">
        <div className="d-flex flex-center w-lg-50">
          <div className="w-lg-420px p-10 mt-5">
            <a href="https://popalpro.com/" className="text-dark">
              {" "}
              <img
                src="assets/popal-lgo.svg"
                alt=""
                className="logo-auth"
              />{" "}
            </a>
            <div className="login-txt pt-5">
              <h3>Log in</h3>
              <p>Welcome back! Please enter your details.</p>
              <form
                className="login-frm pt-2"
                onSubmit={(e) => handleSubmit(handleLogin)(e)}
              >
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {error}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setError("")}
                    ></button>
                  </div>
                )}
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter your email"
                    {...register("email", {
                      required: "*Email is required",

                      onChange: (e) => setEmail(e.target.value),
                    })}
                  />
                  {errors.email && (
                    <small className="text-danger">
                      {errors.email.message}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    onChange={(e) => setPassword(e.target.value)}
                    {...register("password", {
                      required: "*Password is required",

                      onChange: (e) => setPassword(e.target.value),
                    })}
                  />
                  {errors.password && (
                    <small className="text-danger">
                      {errors.password.message}
                    </small>
                  )}
                </div>

                <div className="mb-3 form-rem">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Remember for 30 days
                    </label>
                  </div>
                  <Link to="/forgot-password" className="link-primary">
                    Forgot password
                  </Link>
                </div>

                <div className="mb-3 py-2 d-grid gap-2">
                  {!loading && (
                    <button type="submit" className="btn btn-primary">
                      Sign in
                    </button>
                  )}

                  {loading && (
                    <button type="submit" className="btn btn-primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>

                <div className="text-center pb-5">
                  Don't have an account?
                  <Link to="/signup" className="link-primary px-1">
                    Sign up
                  </Link>
                </div>
              </form>

              {/* <div className="mb-3 py-2">
                <button
                  type="submit"
                  className="btn btn-block btn-primary g-button"
                  onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                >
                  <img src={Icon} alt="" width="35px" height="35px" />
                  Login with google
                </button>
              </div> */}

              <div className="mb-3 py-2 d-grid gap-2">
                <button
                  type="submit"
                  class="btn btn-outline btn-block g-button mb-4"
                  onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                >
                  <img
                    src="assets/gl.png"
                    alt=""
                    width="25px"
                    height="25px"
                    className="google-img"
                  />
                  Continue with Google
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"></div>

        {/* <p className="footr text-gray">© Untitled UI 2077</p> */}
      </div>
    </>
  );
};

export default Login;
