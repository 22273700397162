import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ResetNewPassword = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const handlePasswordReset = async () => {
    setLoading(true);
    const { email } = state;
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword).then(() => {
        toast.success("Password reset successful", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/login");
      });

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex wrapper">
        <div className="d-flex flex-center w-lg-50">
          <div className="w-lg-420px p-10 mt-5">
            <a href="/" className="text-dark">
              <img
                src="assets/resetPasswordKey.png"
                alt=""
                className="logo-auth"
              />
            </a>
            <div className="login-txt pt-5">
              <h3>Set New Password</h3>

              <form
                className="login-frm pt-2"
                onSubmit={(e) => handleSubmit(handlePasswordReset)(e)}
              >
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {error}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setError("")}
                    ></button>
                  </div>
                )}
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter your email"
                    value={state?.email}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="code" className="form-label">
                    Type verification code sent you in your email
                  </label>
                  <input
                    type="text"
                    name="code"
                    className="form-control"
                    id="code"
                    placeholder="Type your 6 digit code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="newPassword" className="form-label">
                    New Password
                  </label>

                  <input
                    type="password"
                    placeholder="New Password"
                    name="newPassword"
                    id="newPassword"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    {...register("newPassword", {
                      required: true,
                      minLength: 8,
                      pattern: {
                        value:
                          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&+*])[a-zA-Z0-9!@#$%^&+*]*$/,
                      },
                      onChange: (e) => setNewPassword(e.target.value),
                    })}
                  />
                  <small className="text-danger">
                    {errors.newPassword &&
                      errors.newPassword.type === "required" && (
                        <span>*Password is required</span>
                      )}

                    {errors.newPassword &&
                      errors.newPassword.type === "minLength" && (
                        <span>
                          Password must be at least 8 characters long!
                        </span>
                      )}

                    {errors.newPassword &&
                      errors.newPassword.type === "pattern" && (
                        <ul>
                          <li> Password must have uppercase letter</li>
                          <li> Password must have lowercase letter</li>
                          <li>
                            Password must have number and special character
                          </li>
                        </ul>
                      )}
                  </small>
                </div>

                <div className="mb-3 py-2 d-grid gap-2">
                  {!loading && (
                    <button type="submit" className="btn btn-primary">
                      Reset Password
                    </button>
                  )}

                  {loading && (
                    <button type="submit" className="btn btn-primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>

                <div className="text-center pb-5">
                  <Link to="/login" className="link-primary px-1">
                    <img src="assets/arrow.png" alt="" />
                    Back to Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"></div>

        {/* <p className="footr text-gray">© Untitled UI 2077</p> */}
      </div>
    </>
  );
};

export default ResetNewPassword;
