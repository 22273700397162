import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const Signup = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLastName] = useState();
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const handleSignUp = async () => {
    setLoading(true);

    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email,
          // "custom:first_name": first_name,
          // "custom:last_name": last_name,
          // "custom:phone": phone,
          // "custom:company": company,
          // "custom:country": country,
        },
      });
      toast.success("Sign Up successful", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });

      navigate("/confirm", {
        state: {
          email,
        },
      });
      setLoading(false);
    } catch (error) {
      setError(error.message);

      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex wrapper">
        <div className="d-flex flex-center w-lg-50 p-4 mt-3">
          <div className="w-lg-590px">
            <a href="https://popalpro.com/" className="text-dark">
              {" "}
              <img
                src="assets/popal-lgo.svg"
                alt=""
                className="logo-auth"
              />{" "}
            </a>
            <div className="login-txt pt-5">
              <h3>Sign up</h3>
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError("")}
                  ></button>
                </div>
              )}

              {/* <p>Welcome back! Please enter your details.</p> */}
              <form
                className="login-frm pt-2"
                onSubmit={(e) => handleSubmit(handleSignUp)(e)}
              >
                {/* <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="formGroupExampleInput" class="form-label">
                      First Name*{" "}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="first_name"
                      placeholder="Your First Name"
                      {...register("first_name", {
                        required: "First Name is required!",
                        minLength: 3,

                        onChange: (e) => setFirst_name(e.target.value),
                      })}
                    />
                    {errors.first_name &&
                      errors.first_name.type === "minLength" && (
                        <small className="text-danger">
                          First Name must be at least 3 characters!
                        </small>
                      )}
                    {errors.first_name && (
                      <small className="text-danger">
                        {errors.first_name.message}
                      </small>
                    )}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="formGroupExampleInput" class="form-label">
                      Last Name*{" "}
                    </label>

                    <input
                      type="text"
                      class="form-control"
                      name="last_name"
                      placeholder="Your Last Name"
                      {...register("last_name", {
                        required: "Last Name is required!",
                        minLength: 3,

                        onChange: (e) => setLastName(e.target.value),
                      })}
                    />
                    {errors.last_name &&
                      errors.last_name.type === "minLength" && (
                        <small className="text-danger">
                          Last Name must be at least 3 characters!
                        </small>
                      )}
                    {errors.last_name && (
                      <small className="text-danger">
                        {errors.last_name.message}
                      </small>
                    )}
                  </div>
                </div> */}

                <div className="mb-3">
                  {/* <div class="col-md-6 mb-3"> */}
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email Address*
                  </label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Your Email Address"
                    {...register("email", {
                      required: "Email Address is required!",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Please enter a Valid Email!",
                      },
                      onChange: (e) => setEmail(e.target.value),
                    })}
                  />
                  <div id="emailHelp" class="form-text fs-10">
                    Email id should be same in Stripe payment too.
                  </div>
                  {errors.email && (
                    <small className="text-danger">
                      {errors.email.message}
                    </small>
                  )}
                </div>

                {/* <div class="col-md-6 mb-3">
                    <label for="formGroupExampleInput" class="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="phone"
                      placeholder="Your Phone Number"
                      {...register("phone", {
                        // pattern: {
                        //   value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                        //   message: "Please enter a Valid number!",
                        // },
                        onChange: (e) => setPhone(e.target.value),
                      })}
                    />
                    {errors.phone && (
                      <small className="text-danger">
                        {errors.phone.message}
                      </small>
                    )}
                  </div> */}
                {/* </div> */}

                {/* <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="formGroupExampleInput" class="form-label">
                      Country*
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="country"
                      placeholder="Your Country"
                      {...register("country", {
                        required: "Country is required!",
                        minLength: 3,
                        onChange: (e) => setCountry(e.target.value),
                      })}
                    />
                    {errors.country && errors.country.type === "minLength" && (
                      <small className="text-danger">
                        Country must be at least 3 characters!
                      </small>
                    )}
                    {errors.country && (
                      <small className="text-danger">
                        {errors.country.message}
                      </small>
                    )}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="formGroupExampleInput" class="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="company"
                      placeholder="Your Company Name"
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                </div> */}

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label
                      htmlFor="examplehtmlFormControlInput1"
                      className="form-label"
                    >
                      Password*
                    </label>
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      {...register("password", {
                        required: true,
                        minLength: 8,
                        pattern: {
                          value:
                            /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&+*])[a-zA-Z0-9!@#$%^&+*]*$/,
                        },

                        onChange: (e) => setPassword(e.target.value),
                      })}
                    />
                    <small className="text-danger">
                      {errors.password &&
                        errors.password.type === "required" && (
                          <span>Password is required!</span>
                        )}

                      {errors.password &&
                        errors.password.type === "minLength" && (
                          <span>Password must be at least 8 characters!</span>
                        )}

                      {errors.password &&
                        errors.password.type === "pattern" && (
                          <ul>
                            <li> Password must have uppercase letter</li>
                            <li> Password must have lowercase letter</li>
                            <li>
                              Password must have number and special character
                            </li>
                          </ul>
                        )}
                    </small>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Confirm Password*
                    </label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      {...register("confirmPassword", {
                        validate: (match) => {
                          const password = getValues("password");
                          return match === password || "Password should match";
                        },
                        onChange: (e) => setConfirmPassword(e.target.value),
                      })}
                    />

                    <small className="text-danger">
                      {errors.confirmPassword?.message}
                    </small>
                  </div>
                </div>

                <div className="mb-3 py-2 d-grid gap-2">
                  {!loading && (
                    <button type="submit" className="btn btn-primary">
                      Sign up
                    </button>
                  )}

                  {loading && (
                    <button type="submit" className="btn btn-primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
                <div className="text-center pb-5">
                  Already have an account?
                  <Link to="/login" className="link-primary px-1">
                    Log in
                  </Link>
                </div>
              </form>

              <div className="mb-3 py-2 d-grid gap-2">
                <button
                  type="submit"
                  class="btn btn-outline mb-4"
                  onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                >
                  <img
                    src="assets/gl.png"
                    alt=""
                    width="25px"
                    height="25px"
                    className="google-img"
                  />
                  Continue with Google
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"></div>

        {/* <p className="footr text-gray text-center">© Untitled UI 2077</p> */}
      </div>
    </>
  );
};

export default Signup;
