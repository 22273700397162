import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ContactModal from "./contactModal";
import axios from "axios";
import { getAuthToken } from "../utils/token.utils";

const PrivacyPolicy = () => {
  let navigate = useNavigate();
  const [suscription, setsuscription] = useState("");
  const [customerpotalurl, SetCustomerpotalurl] = useState("");
  const token = getAuthToken();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const handleLogin = async (e) => {
    try {
      await Auth.signIn(email, password);
      navigate("/home");
    } catch (error) {
      setError(error.message);
    }
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    // const return_url = process.env.STRIPE_RETURN_URL;
    // const return_url = "https://d3ouejn2p8m8vy.cloudfront.net/home";
    const return_url = "http://localhost:3000/";
    // console.log(return_url);
    // setIsLoading(true);
    await axios
      .post(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/stripe-customer-portal",
        { return_url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        // console.log(res);
        SetCustomerpotalurl(res.data.body.data?.session_url);
        window.location.replace(res.data.body.data?.session_url);
      });
  };
  const [isSubscription, setIsSubscription] = useState(
    localStorage.getItem("isSubscription") === "true"
  );
  const showFreeTrial = !token && !isSubscription;

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="navbar-light bg-white border-bottom"
        sticky="top"
      >
        <div className="container">
          <Navbar.Brand className="navbar-brand lgo" href="/">
            {" "}
            <img src="assets/popal-lgo.svg" alt="" className="logo-home" />
          </Navbar.Brand>
          {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            {/* Start::Dropdown  */}
            {/* {token && LastAuthUser} */}
            <Nav>
              <NavLink to="/pricing" className="nav-link text-lgt pe-4">
                Pricing
              </NavLink>
              {token ? (
                <NavLink to="/home" className="nav-link text-lgt pe-0">
                  Dashboard
                </NavLink>
              ) : (
                <NavLink to="/login" className="nav-link text-lgt pe-0">
                  Login
                </NavLink>
              )}
              {/* <button  className="btn btn-md btn-primary flex items-center">Free Trial
        </button> */}

              {showFreeTrial && (
                <Button variant="primary mobille-vw ms-4" onClick={handleShow}>
                  Free Trial
                </Button>
              )}
              <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter text-center"
                centered
              >
                <Modal.Header className="trial">
                  <Modal.Title className="text-center pt-5 w-100">
                    Signup for free trial
                  </Modal.Title>
                  <button
                    type="button"
                    class="btn-close close-icon"
                    aria-label="Close"
                    onClick={handleClose}
                  ></button>
                </Modal.Header>
                <Modal.Body className="text-center ">
                  <button
                    type="submit"
                    class="btn btn-outline google mb-4"
                    onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                  >
                    <img
                      src="assets/gl.png"
                      alt=""
                      width="25px"
                      height="25px"
                      className="google-img"
                    />
                    Continue with Google
                  </button>
                  <p className="text-lgt pt-4 pb-4">Or use your email</p>
                  {error && (
                    <div className="alert alert-danger text-center">
                      {error}
                    </div>
                  )}
                  <input
                    type="email"
                    class="form-control mod-email"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email"
                    {...register("email", {
                      required: "*Email is required",

                      onChange: (e) => setEmail(e.target.value),
                    })}
                  ></input>
                  {errors.email && (
                    <small className="text-danger">
                      {errors.email.message}
                    </small>
                  )}

                  <input
                    type="password"
                    class="form-control mod-email mt-3"
                    name="password"
                    placeholder="Password"
                    {...register("password", {
                      required: "*Password is required",

                      onChange: (e) => setPassword(e.target.value),
                    })}
                  ></input>
                  {errors.password && (
                    <small className="text-danger">
                      {errors.password.message}
                    </small>
                  )}

                  <div class="mb-3 py-2  mt-3">
                    <button
                      class="btn btn-block btn-primary g-button btn-ggl"
                      onClick={(e) => handleSubmit(handleLogin)(e)}
                    >
                      Continue with Email
                    </button>
                  </div>
                  <div class="text-center pt-2 pb-5">
                    Don't have an account?{" "}
                    <a href="/signup" class="link-primary px-1">
                      Signup
                    </a>
                  </div>
                </Modal.Body>
                <Modal.Footer className="trial text-center">
                  <div class="text-center w-100">
                    By creating an account you agree to our
                    <a href="/privacy-policy" class="link-primary px-1">
                      Privacy & Terms
                    </a>
                  </div>
                </Modal.Footer>
              </Modal>
            </Nav>
            {/* End::Dropdowm */}
          </Navbar.Collapse>
        </div>
      </Navbar>

      <section className="feature-section1">
        <div className="container feature-space">
          <div className="feature-text align-items-center text-white text-center">
            <div className="feature-txt">
              <h1 className="text-white">Terms and Privacy Policy</h1>
              {/* <p className="pt-3 pb-4">
                We take the privacy of our users seriously. This privacy policy outlines how we collect, use, and protect <br /> the information you provide to us through the use of our SaaS utility.  </p> */}
            </div>
          </div>

          <div className="col-md-6 feature-img">
            {/* <img src="assets/banner.svg" className="feature-img" alt="" /> */}
          </div>
        </div>
      </section>

      {/* Begin::Privacy Content */}
      <div className="py-10 bg-lgt">
        <div className="container">
          <h3 className="pb-1">Privacy Policy</h3>
          <p className="mb-5">
            We take the privacy of our users seriously. This privacy policy
            outlines how we collect, use, and protect the information you
            provide to us through the use of our SaaS utility.
          </p>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">1. Information Collected:</h4>
            <p>
              We collect user information such as name, emal address,
              payment/credit card information, and any other necessary
              information required to process payments and generate user
              stories. We also collect the application requirements that you
              provide as input to our system. We do not collect any sensitive
              personal information such as financial information or social
              security numbers.{" "}
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">2. Use of Information:</h4>
            <p>
              We utilize your data only for the purpose of processing payments,
              training our model, and generating user stories. We do not provide
              your information to any third-party companies. We take necessary
              precautions to protect your data and ensure that it is not
              misused.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">3. Protection of Information:</h4>
            <p>
              We use reasonable and appropriate measures to protect your
              information from unauthorized access, use, and disclosure. We use
              secure servers to store your information and access is limited
              only to authorized personnel.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">4. User Control:</h4>
            <p>
              You have the right to control and access your information. If you
              wish to have your information removed from our system, you can
              request us to do so by contacting us at [insert contact
              information].
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">5. Use of ChatGPT:</h4>
            <p>
              We utilize ChatGPT to generate user stories. However, we will not
              reveal your company or user identity to ChatGPT. We take necessary
              measures to ensure that your data is protected and is not exposed
              to ChatGPT.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">6. Payment Processing:</h4>
            <p>
              We will be utilizing your payment/credit card information to
              process payment. We utilize Stripe for payment processing, which
              is a highly secure, industry-leading payment processing system.
            </p>
          </div>

          <h3 className="pt-5">Terms of Usage:</h3>
          <p className="mb-5">
            By using our SaaS utility, you agree to the following terms:
          </p>

          <div className="text-start mb-4">
            <h4 className="pb-1">1. User Data:</h4>
            <p>
              You are responsible for the accuracy and completeness of the
              information you provide to us, including the application
              requirements you provide as input to our system. We utilize your
              data only for the purpose of processing payments, training our
              model, and generating user stories. We do not provide your
              information to any third-party companies.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">2. User Identity:</h4>
            <p>
              We will not reveal your company or user identity to ChatGPT.
              However, we cannot guarantee that your information will remain
              completely anonymous. We take reasonable measures to protect your
              identity, but we cannot guarantee absolute privacy.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">3. Ownership</h4>
            <p>
              We own all rights to the software, data, and content provided
              through the use of our SaaS utility. You may not reproduce,
              distribute, or otherwise use any of our materials without our
              written consent.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">4. Termination</h4>
            <p>
              We reserve the right to terminate your use of our SaaS utility at
              any time for any reason. You may also terminate your use of our
              SaaS utility at any time by contacting us.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">5. License</h4>
            <p>
              Our service is licensed, not sold, and is subject to the terms of
              the license agreement. Based on the license, we have a limitation
              of requests that you can process per month. You may not exceed the
              limit set by your license without obtaining additional
              authorization from us.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">6. Payment and Billing</h4>
            <p>
              We automatically bill you for monthly/yearly subscription until
              you cancel your subscription. We reserve the right to change our
              fees or billing methods at any time. You are responsible for
              paying all fees and taxes associated with your use of our SaaS
              utility.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">7. Dispute Resolution:</h4>
            <p>
              Any dispute arising out of or related to this agreement shall be
              governed by the laws of the State of Georgia, USA, without regard
              to its conflict of law provisions. The parties agree to submit to
              the personal jurisdiction of the state and federal courts located
              in the State of Georgia.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">8. Disclaimer of Liability:</h4>
            <p>
              We are not liable for any damages or losses that may occur as a
              result of using our SaaS utility. We are not responsible for any
              indirect, consequential, or incidental damages that may arise from
              your use of our service. We are not liable for any damages
              resulting from your use of our service, including but not limited
              to, loss of data or revenue, or any damages resulting from
              unauthorized access to or alteration of your data.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">9. Indemnification:</h4>
            <p>
              You agree to indemnify, defend and hold harmless our company, our
              affiliates, and our officers, directors, employees, agents, and
              licensors from and against any and all claims, liabilities,
              damages, losses, costs, expenses, or fees arising from your use of
              our SaaS utility, or your breach of these terms of use.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">10. Modification of Terms:</h4>
            <p>
              We reserve the right to modify these terms of use at any time. We
              will notify you of any material changes by email or by posting a
              notice on our website. Your continued use of our SaaS utility
              after any changes to these terms of use will indicate your
              acceptance of the revised terms.
            </p>
          </div>

          <div className="text-start mb-4">
            {/* <span className="color-primary">Sample</span> */}
            <h4 className="pb-1">11. User Story Accuracy:</h4>
            <p>
              Our SaaS utility utilizes advanced natural language processing
              technology to generate user stories based on the input and
              requirements you provide. However, please note that the user
              stories generated may not always be 100% accurate or reflective of
              your intended meaning. It is your responsibility to review and
              ensure that the user stories generated by our SaaS utility
              accurately reflect your intended meaning before using or
              implementing them.
            </p>
          </div>

          <p>
            By using our SaaS utility, you acknowledge that you have read,
            understood, and agreed to these terms of use and our privacy policy.
            If you do not agree to these terms, you should not use our SaaS
            utility.
          </p>
        </div>
      </div>

      {/* ENd::Privacy Content */}

      {/* Begin::Footer */}
      <footer className="footer border-top p-4">
        <div className="container">
          <div className="copyright-txt">
            <span>Copyright 2023 AgileMove Inc. All Rights Reserved.</span>
            <div className="text-end d-flex">
              <a href="/privacy-policy" className="text-drk me-4 ">
                Privacy Policy
              </a>

              <ContactModal isFooter></ContactModal>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PrivacyPolicy;
