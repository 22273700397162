import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ContactModal from "./contactModal";
import Stripe from "./stripe";
import { getAuthToken } from "../utils/token.utils";

const Pricing = () => {
  let navigate = useNavigate();
  const [subscription, setsubscription] = useState("");
  const [customerpotalurl, SetCustomerpotalurl] = useState("");
  const token = getAuthToken();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const handleLogin = async (e) => {
    try {
      await Auth.signIn(email, password);
      navigate("/home");
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/retrieve-user-subscription",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setsubscription(response.data.body.data.product.active);
      })
      .catch((error) => {});
    window.scrollTo(0, 0);
  }, []);
  // const handleGetStarted = () => {
  //   if (token && subscription) {
  //     navigate("/subscription");
  //   } else {
  //     navigate("/login", { state: { pricingLogin: true } });
  //   }
  // };

  const submitHandler = async (event) => {
    event.preventDefault();
    // const return_url = process.env.STRIPE_RETURN_URL;
    // const return_url = "https://d3ouejn2p8m8vy.cloudfront.net/home";
    const return_url = "http://localhost:3000/";
    // setIsLoading(true);
    await axios
      .post(
        "https://gzv6bp5l0l.execute-api.us-east-1.amazonaws.com/dev/api/stripe-customer-portal",
        { return_url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        SetCustomerpotalurl(res.data.body.data?.session_url);
        window.location.replace(res.data.body.data?.session_url);
      });
  };

  const [isSubscription, setIsSubscription] = useState(
    localStorage.getItem("isSubscription") === "true"
  );
  const showPricingTable = token && !isSubscription;
  const showFreeTrial = !token && !isSubscription;
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="navbar-light bg-white border-bottom"
        sticky="top"
      >
        <div className="container">
          <Navbar.Brand className="navbar-brand lgo" href="/">
            {" "}
            <img src="assets/popal-lgo.svg" alt="" className="logo-home" />
          </Navbar.Brand>
          {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            {/* Start::Dropdown  */}
            {/* {token && LastAuthUser} */}
            <Nav>
              <NavLink to="/pricing" className="nav-link text-lgt pe-4">
                Pricing
              </NavLink>
              {token ? (
                <NavLink to="/home" className="nav-link text-lgt pe-0">
                  Dashboard
                </NavLink>
              ) : (
                <NavLink to="/login" className="nav-link text-lgt pe-0">
                  Login
                </NavLink>
              )}
              {/* <button  className="btn btn-md btn-primary flex items-center">Free Trial
        </button> */}

              {showFreeTrial && (
                <Button variant="primary mobille-vw ms-4" onClick={handleShow}>
                  Free Trial
                </Button>
              )}
              <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter text-center"
                centered
              >
                <Modal.Header className="trial">
                  <Modal.Title className="text-center pt-5 w-100">
                    Signup for free trial
                  </Modal.Title>
                  <button
                    type="button"
                    class="btn-close close-icon"
                    aria-label="Close"
                    onClick={handleClose}
                  ></button>
                </Modal.Header>
                <Modal.Body className="text-center ">
                  <button
                    type="submit"
                    class="btn btn-outline google mb-4"
                    onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                  >
                    <img
                      src="assets/gl.png"
                      alt=""
                      width="25px"
                      height="25px"
                      className="google-img"
                    />
                    Continue with Google
                  </button>
                  <p className="text-lgt pt-4 pb-4">Or use your email</p>
                  {error && (
                    <div className="alert alert-danger text-center">
                      {error}
                    </div>
                  )}
                  <input
                    type="email"
                    class="form-control mod-email"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email"
                    {...register("email", {
                      required: "*Email is required",

                      onChange: (e) => setEmail(e.target.value),
                    })}
                  ></input>
                  {errors.email && (
                    <small className="text-danger">
                      {errors.email.message}
                    </small>
                  )}

                  <input
                    type="password"
                    class="form-control mod-email mt-3"
                    name="password"
                    placeholder="Password"
                    {...register("password", {
                      required: "*Password is required",

                      onChange: (e) => setPassword(e.target.value),
                    })}
                  ></input>
                  {errors.password && (
                    <small className="text-danger">
                      {errors.password.message}
                    </small>
                  )}

                  <div class="mb-3 py-2  mt-3">
                    <button
                      class="btn btn-block btn-primary g-button btn-ggl"
                      onClick={(e) => handleSubmit(handleLogin)(e)}
                    >
                      Continue with Email
                    </button>
                  </div>
                  <div class="text-center pt-2 pb-5">
                    Don't have an account?{" "}
                    <a href="/signup" class="link-primary px-1">
                      Signup
                    </a>
                  </div>
                </Modal.Body>
                <Modal.Footer className="trial text-center">
                  <div class="text-center w-100">
                    By creating an account you agree to our
                    <a href="/privacy-policy" class="link-primary px-1">
                      Privacy & Terms
                    </a>
                  </div>
                </Modal.Footer>
              </Modal>
            </Nav>
            {/* End::Dropdowm */}
          </Navbar.Collapse>
        </div>
      </Navbar>

      {/* Main Banner Section */}
      {/* <section className="feature-section1">
        <div className="container feature-space">
          <div className="feature-text align-items-center text-white text-center">
            <div className="feature-txt">
              <h1 className="text-white">Flexible Plans & Pricing</h1>
            </div>
            <a
              href="https://billing.stripe.com/p/login/test_7sIaFT0wn7jAgo05kk"
              target="_blank"
            >
              <button className="btn btn-md btn-primary flex items-center mt-3">
                Update you Plan
              </button>
            </a>
          </div>
        </div>
      </section> */}

      {/* Start::Payment Subscription */}
      {showPricingTable ? (
        <div className="py-10 landing-bg-light" id="pricing">
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="tex">Choose your Subscription Plan</h2>
            </div>
            <Stripe></Stripe>
          </div>
        </div>
      ) : (
        <div className="py-10 landing-dark-bg">
          <div className="container">
            <div className="text-center text-white mb-5">
              <h2 className="text-white">Choose your Subscription Plan</h2>
            </div>

            {/* begin::Row */}
            <div className="row">
              {/* start::1st col */}
              {!showPricingTable && (
                <div className="col-md-3 mb-2">
                  <div className="card h-100">
                    <div className="d-flex h-100 align-items-center">
                      <div class="w-100 d-flex flex-column flex-center rounded-3 bg-body py-5 px-4">
                        {/* Begin::Heading */}
                        <div class="mb-7 text-center">
                          <h3 class="text-dark mb-2 fw-boldest">Free</h3>
                          {/*<div class="text-gray-400 fw-semibold mb-3">
                        Best Features for Standard Plan
                      </div>*/}

                          {/* <!--begin::Price--> */}
                          <div class="text-center mb-3">
                            <span class="mb-3 text-primary">$</span>

                            <span class="fs-3x fw-bold text-primary">0.00</span>
                            <span
                              class="fs-7 fw-semibold opacity-50"
                              data-kt-plan-price-month="/ Mon"
                              data-kt-plan-price-annual="/ Ann"
                            >
                              / Monthly
                            </span>
                          </div>
                          {/* <!--end::Price--> */}
                        </div>
                        {/* End::Heading */}

                        {/* Begin:: Feature */}
                        <div class="w-100">
                          <div class="d-flex justify-content-between mb-3">
                            <span class="fw-semibold fs-8 text-gray-800 text-center pe-3">
                              Allows 10 requests per month. Generates about 20
                              to 50 User stories with Acceptance criteria per
                              month.
                            </span>
                          </div>
                        </div>
                        {/* ENd:: Feature */}

                        {token && isSubscription ? (
                          <Link
                            class="btn btn-primary mt-4 "
                            onClick={submitHandler}
                          >
                            Get Started
                          </Link>
                        ) : (
                          <Link to="/login" class="btn btn-primary mt-4">
                            Get Started
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* end::2ns col */}

              <div className="col-md-3 mb-2">
                <div className="card h-100">
                  <div className="d-flex h-100 align-items-center">
                    <div class="w-100 d-flex flex-column flex-center rounded-3 bg-body py-5 px-4">
                      {/* Begin::Heading */}
                      <div class="mb-7 text-center">
                        <h3 class="text-dark mb-2 fw-boldest">Standard</h3>
                        {/*<div class="text-gray-400 fw-semibold mb-3">
                          Best Features for Standard Plan
                        </div>*/}

                        {/* <!--begin::Price--> */}
                        <div class="text-center mb-3">
                          <span class="mb-3 text-primary">$</span>

                          <span class="fs-3x fw-bold text-primary">3.99</span>
                          <span
                            class="fs-7 fw-semibold opacity-50"
                            data-kt-plan-price-month="/ Mon"
                            data-kt-plan-price-annual="/ Ann"
                          >
                            / Monthly
                          </span>
                        </div>
                        {/* <!--end::Price--> */}
                      </div>
                      {/* End::Heading */}

                      {/* Begin:: Feature */}
                      <div class="w-100">
                        <div class="d-flex justify-content-between mb-3">
                          <span class="fw-semibold fs-8 text-gray-800 text-center pe-3">
                            Allows 35 requests per month. Generates about 100 to
                            200 User stories with Acceptance criteria per month.
                          </span>
                        </div>
                      </div>
                      {/* ENd:: Feature */}

                      {token && isSubscription ? (
                        <Link
                          class="btn btn-primary mt-4 "
                          onClick={submitHandler}
                        >
                          Get Started
                        </Link>
                      ) : (
                        <Link to="/login" class="btn btn-primary mt-4">
                          Get Started
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* start:: 2nd col */}
              <div className="col-md-3 mb-2">
                <div className="card rounded h-100">
                  <div className="d-flex h-100 align-items-center bg-sec">
                    <div class="w-100 d-flex flex-column flex-center rounded-3 bg-sec py-5 px-4">
                      {/* Begin::Heading */}
                      <div class="mb-7 text-center text-white">
                        <h3 class="text-white mb-2 fw-boldest">Premium</h3>
                        {/* <div class="text-white-400 fw-semibold mb-3">
                          Best Features for Premium Plan
                        </div>*/}

                        {/* <!--begin::Price--> */}
                        <div class="text-center mb-3">
                          <span class="mb-3 text-white">$</span>

                          <span class="fs-3x fw-bold text-white">6.99</span>
                          <span
                            class="fs-7 fw-semibold opacity-50"
                            data-kt-plan-price-month="/ Mon"
                            data-kt-plan-price-annual="/ Ann"
                          >
                            / Monthly
                          </span>
                        </div>
                        {/* <!--end::Price--> */}
                      </div>
                      {/* End::Heading */}

                      {/* Begin:: Feature */}
                      <div class="w-100 text-white">
                        <div class="d-flex justify-content-between mb-3 text-white">
                          <span class="fw-semibold fs-8 text-gray-800 text-center pe-3">
                            Allows 75 requests per month. Generates about
                            200-400 user stories with acceptance criteria per
                            month.
                          </span>
                        </div>
                      </div>
                      {/* ENd:: Feature */}

                      {token && isSubscription ? (
                        <Link
                          class="btn btn-light mt-4"
                          onClick={submitHandler}
                        >
                          Get Started
                        </Link>
                      ) : (
                        <Link to="/login" class="btn btn-light mt-4">
                          Get Started
                        </Link>
                      )}
                      {/* <div
                        class="btn btn-light mt-4"
                        onClick={handleGetStarted}
                      >
                        Get Started
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* end:: 2nd col */}

              {/* start:: 3rd col */}
              <div className="col-md-3 mb-2">
                <div className="card h-100">
                  <div className="d-flex h-100 align-items-center">
                    <div class="w-100 d-flex flex-column flex-center rounded-3 bg-body py-5 px-4">
                      {/* Begin::Heading */}
                      <div class="mb-7 text-center">
                        <h3 class="text-dark mb-3 fw-boldest">Enterprise</h3>
                        {/* <div class="text-gray-400 fw-semibold mb-3">
                         Best Features for Enterprise Plan
                         </div>*/}

                        {/* <!--begin::Price--> */}
                        <div class="text-center mb-3">
                          <span class="mb-3">
                            Requests per user per month limit can be tailored to
                            specific needs. Pricing will depend on the number of
                            requests per user per month and the total number of
                            users.
                          </span>
                        </div>
                        {/* <!--end::Price--> */}
                      </div>
                      {/* End::Heading */}

                      <ContactModal isFooter={false}></ContactModal>
                    </div>
                  </div>
                </div>
              </div>
              {/* end:: 3rd col */}
            </div>
            {/* end::Row */}
          </div>
        </div>
      )}
      {/* End::Payment Subscription */}

      {/* Begin::Footer */}
      <footer className="footer border-top p-4">
        <div className="container">
          <div className="copyright-txt">
            <span>Copyright 2023 AgileMove Inc. All Rights Reserved.</span>
            <div className="text-end d-flex">
              <a href="/privacy-policy" className="text-drk me-4 ">
                Privacy Policy
              </a>

              <ContactModal isFooter></ContactModal>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Pricing;
