import jwtDecode from "jwt-decode";

const getAuthToken = () => {
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID
    }.${localStorage.getItem(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`
    )}.idToken`
  );
  return token;
};

const getAccesstoken = () => {
  const accesstoken = localStorage.getItem("accessToken");
  console.log(accesstoken);
  return accesstoken;
};

const getDecodedToken = (token) => {
  const decodeToken = jwtDecode(token);
  return decodeToken;
};

export { getAuthToken, getDecodedToken, getAccesstoken };
