import React, { useState, useEffect } from "react";
import LayoutComponent from "../shared/LayoutComponent";
import SidebarComponent from "../shared/SidebarComponent";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import jwtdecode from "jwt-decode";
import axios from "axios";
import { getAuthToken } from "../utils/token.utils";
import { Link } from "react-router-dom";
const Overview = (props) => {
  async function updateUser() {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:favorite_ice_cream": "vanilla",
    });
  }
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLastName] = useState();
  const token = getAuthToken();

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const handleLogin = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // console.log(user);
      await Auth.updateUserAttributes(user, {
        email,
        "custom:first_name": first_name,
        "custom:last_name": last_name,
      });
      // console.log(user);
    } catch (error) {
      // console.log(error.message);
    }
  };

  const decodeToken = jwtdecode(token);
  const LastAuthUser = decodeToken.email;
  const firstName = decodeToken["custom:first_name"];
  const lastName = decodeToken["custom:last_name"];
  const phone = decodeToken["custom:phone"];
  const company = decodeToken["custom:company"];
  const country = decodeToken["custom:country"];

  useEffect(() => {
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/user-profile/",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        localStorage.getItem(
          "profile",
          JSON.stringify(response.data.body.data)
        );
        localStorage.setItem(
          "profile",
          JSON.stringify(response.data.body.data)
        );
        localStorage.setItem(
          "profile",
          JSON.stringify(response.data.body.data)
        );
        setUser(response.data.body.data);
      })
      .catch((error) => {});
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Dashboard */}
      <LayoutComponent>
        {/* Start::Mobile nav icon */}
        <div className="container d-md-none d-block pt-3 pb-2">
          <button className="btn px-1 py-0 open-btn">
            <i className="bi bi-list"></i>
          </button>
        </div>
        {/* End::Mobile nav icon */}
        <div className="main-container d-flex">
          <SidebarComponent></SidebarComponent>
          <div className="content py-5 px-5">
            <div className="container">
              <div className="profile-header d-flex ">
                <h4 className="card-title align-items-start flex-column pb-4">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Profile Detail
                  </span>
                </h4>
                <div className="card-toolbar">
                  <Link to="/profile" className="btn btn-sm btn-primary">
                    Edit Profile{" "}
                    <span className="svg-icon svg-icon-2 px-1">
                      {" "}
                      <i className="bi bi-pencil"></i>{" "}
                    </span>
                  </Link>
                </div>
              </div>

              <div className="card-body p-9 w-75">
                <div className="row mb-3 table-strp">
                  <label className="col-lg-4 fw-semibold text-muted">
                    First Name
                  </label>
                  <div className="col-lg-8">
                    <span className="fs-8 text-gray-600">
                      {user.first_name}
                    </span>
                  </div>
                </div>

                <div className="row mb-3 table-strp">
                  <label className="col-lg-4 fw-semibold text-muted">
                    Last Name
                  </label>
                  <div className="col-lg-8">
                    <span className="fs-8 text-gray-600">{user.last_name}</span>
                  </div>
                </div>

                <div className="row mb-3 table-strp">
                  <label className="col-lg-4 fw-semibold text-muted">
                    Email
                  </label>
                  <div className="col-lg-8">
                    <span className="fs-8 text-gray-600">{user.email}</span>
                  </div>
                </div>

                <div className="row mb-3 table-strp">
                  <label className="col-lg-4 fw-semibold text-muted">
                    Phone Number
                  </label>
                  <div className="col-lg-8">
                    <span className="fs-8 text-gray-600">{user.phone}</span>
                  </div>
                </div>

                <div className="row mb-3 table-strp">
                  <label className="col-lg-4 fw-semibold text-muted">
                    Country Name
                  </label>
                  <div className="col-lg-8">
                    <span className="fs-8 text-gray-600">{user.country}</span>
                  </div>
                </div>

                <div className="row mb-3 table-strp">
                  <label className="col-lg-4 fw-semibold text-muted">
                    Company Name
                  </label>
                  <div className="col-lg-8">
                    <span className="fs-8 text-gray-600">{user.company}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};

export default Overview;
