import React, { useEffect, useState } from 'react';
import { getAuthToken, getDecodedToken } from '../utils/token.utils';
import { STRIPE_PRICE_TABLE, STRIPE_PUBLISH_KEY } from '../configs/stripe';

const Stripe = () => {
  const token = getAuthToken();
  const [customerEmail, setCustomerEmail] = useState(null);

  useEffect(() => {
    loadCustomerEmail();
  }, [token]);

  const loadCustomerEmail = () => {
    if (!token) {
      setCustomerEmail(null);
      return;
    }
    const decodedToken = getDecodedToken(token);
    const LastAuthUser = decodedToken?.email;
    setCustomerEmail(LastAuthUser); 
  }

  return (
    <>
        <stripe-pricing-table
          pricing-table-id={STRIPE_PRICE_TABLE}
          publishable-key={STRIPE_PUBLISH_KEY}
          customer-email={customerEmail}
        ></stripe-pricing-table>
    </>
  );
};

export default Stripe;
