import React, { useState } from 'react';
import LayoutComponent from "../shared/LayoutComponent";
import FooterComponent from "../shared/FooterComponent";
import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { getAuthToken } from "../utils/token.utils";

const Help = () => {
  const [customerpotalurl, SetCustomerpotalurl] = useState("");
  const token = getAuthToken();
  const [isSubscription, setIsSubscription] = useState(
    localStorage.getItem("isSubscription") === "true"
  );
  const submitHandler = async (event) => {
    event.preventDefault();
    // const return_url = process.env.STRIPE_RETURN_URL;
    // const return_url = "https://d3ouejn2p8m8vy.cloudfront.net/home";
    const return_url = "http://localhost:3000/";
    // console.log(return_url);
    // setIsLoading(true);
    await axios
      .post(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/stripe-customer-portal",
        { return_url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        // console.log(res);
        SetCustomerpotalurl(res.data.body.data?.session_url);
        window.location.replace(res.data.body.data?.session_url);
      });
  };
  return (
    <>
         <LayoutComponent>
         <div className="container pt-5">
         <div className="header-title text-center mt-4 mb-4">
            <h2 className="wv-heading--title">Hi! Welcome to the POPal helpdesk!</h2>
            <p className="wv-heading--subtitle">
            Here you find all solutions to the most common issues. And of course, you can also submit a <a href='/contact' className='link-clr'>support request or ask </a>for a new feature!
            </p>
          </div>

{/* Start::Collapse */}
<div className="accordan d-flex justify-content-center pt-3 mb-4">
<Accordion className="w-75">
  <h3 className="text-center pb-3 text-lgt">Account</h3>
      <Accordion.Item eventKey="0">
        <Accordion.Header>How do I cancel my subscription? </Accordion.Header>
        <Accordion.Body>
        You can cancel a POPal existing subscription at any time via the “Subscription” section on the {isSubscription ? (
            <NavLink
              to="/subscription" className="link-clr"
              onClick={submitHandler}
            >
              Account Page
            </NavLink>
          ) : (
            <NavLink to="/plan" className="link-clr">
             Account Page
            </NavLink>
          )}. When you cancel an existing subscription, you will still have access to all  features for the rest of the current billing period.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How do I change my password?</Accordion.Header>
        <Accordion.Body>
        Once you log in, you can update this information via the <NavLink to="/change-password" className='link-clr'>Account Page</NavLink>. If you don’t remember your password, you can request a password reset email via the login page. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>How many requests can I make during my free trial?</Accordion.Header>
        <Accordion.Body>
        During your free trial, you can generate up to 10 requests for user stories.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>How do I subscribe to your service?</Accordion.Header>
        <Accordion.Body>
        Once you log in, you can subscribe via the subscription menu in the {isSubscription ? (
            <NavLink
              to="/subscription" className="link-clr"
              onClick={submitHandler}
            >
            Account Page
            </NavLink>
          ) : (
            <NavLink to="/plan" className="link-clr">
              Account Page
            </NavLink>
          )}.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>Why do I still get a limitation message with a paid account?</Accordion.Header>
        <Accordion.Body>
        The Standard plan allows a maximum of 35 requests per month, while the Premium plan allows up to 75 requests per month. If you require unlimited requests, please <a href='/contact' className='link-clr'>Contact us</a> for the Enterprise Plan.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>How can I purchase this service for my organization?</Accordion.Header>
        <Accordion.Body>
        Please <a href='/contact' className='link-clr'>Contact us</a> to purchase plans for multiple users on behalf of your organization.

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>

    <div className="accordan d-flex justify-content-center pt-3 mb-4">
    <Accordion className="w-75">
  <h3 className="text-center pb-3 text-lgt">Payment</h3>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Do I need payment details for a free trial?</Accordion.Header>
        <Accordion.Body>
        No, you do not need to provide any credit card information for the free trial. You will have the opportunity to try 10 requests for free.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How secure is your payment processing? </Accordion.Header>
        <Accordion.Body>
        We use Stripe for all payment processing and invoicing. Stripe is one of the most popular and secure payment processing applications available in the market, so you can be fully assured of the security of the payment processing.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Which payment method do you support?</Accordion.Header>
        <Accordion.Body>
        We accept the following credit/debit cards:
        <ul><li>Visa</li>
        <li>Mastercard</li>
        <li>American Express</li>
        <li>Discover</li>
        </ul>
        <p className="pt-0">You can also pay using Google Pay.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>How does auto renewal work? </Accordion.Header>
        <Accordion.Body>
        POPal subscriptions will always auto-renew to prevent disruptions to your work. If you want to turn off the auto-renew please  <a href='/contact' className='link-clr'>Contact us</a>. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>How do I update my payment method?</Accordion.Header>
        <Accordion.Body>
        Once you log in, you can update this information via the Subscription menu in the {isSubscription ? (
            <NavLink
              to="/subscription" className="link-clr"
              onClick={submitHandler}
            >
              Account Page
            </NavLink>
          ) : (
            <NavLink to="/plan" className="link-clr">
             Account Page
            </NavLink>
          )}. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>Can I download my invoice?</Accordion.Header>
        <Accordion.Body>
        Once you log in to your account, you can view and download all previous invoices on the Account page, via the Subscription.  
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>


    <div className="accordan d-flex justify-content-center pt-3 mb-5">
    <Accordion className="w-75">
  <h3 className="text-center pb-3 text-lgt">Functionality</h3>
      <Accordion.Item eventKey="0">
        <Accordion.Header>How accurate are the user stories that are generated?</Accordion.Header>
        <Accordion.Body>
        We customized ChatGPT to generate user stories, so they are pretty accurate. However  please review the user stories generated before uploading them to your system.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How long can a requirement be when entering it?</Accordion.Header>
        <Accordion.Body>
        You can enter requirements of up to 1000 words at a time. If you have a long requirement, you may break it up into logical groupings and make multiple requests.        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>How can I view the user stories that have been generated?</Accordion.Header>
        <Accordion.Body>
        Please go to the 'Request History' section and download the CSV file. You can then view it using any CSV file viewer. We provide the user stories in a CSV file format so that you can easily upload them into the ALM tool that you are using.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className='mb-5'>
        <Accordion.Header>How can I import these stories into my ALM tool, such as Jira, Rally, Azure DevOps, etc.?</Accordion.Header>
        <Accordion.Body>
        Please download the CSV file and refer to the user guide of your ALM tool for instructions on how to upload it. Typically, all ALM tools allow users to upload user stories from a CSV file.        
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
{/* End::Collapse */}
          </div>

         <FooterComponent />
         </LayoutComponent>
    </>
  )
}

export default Help
