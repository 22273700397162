import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import jwtdecode from "jwt-decode";
import axios from "axios";
import $ from "jquery";
import { getAuthToken } from "../utils/token.utils";

// import $ from 'jquerycode';

// import LayoutComponent from './LayoutComponent'
import { Link } from "react-router-dom";
import { useAuthStateContext } from "../context/UserProvider";
const SidebarComponent = () => {
  //  Begin::Responsive Sidebar
  $(".open-btn").on("click", function () {
    $(".sidebar").addClass("active");
  });

  $(".close-btn").on("click", function () {
    $(".sidebar").removeClass("active");
  });
  // Begin::Responsive Sidebar

  let navigate = useNavigate();
  const [user, setUser] = useState("");
  const [customerpotalurl, SetCustomerpotalurl] = useState("");
  const token = getAuthToken();

  const decodeToken = jwtdecode(token);
  const LastAuthUser = decodeToken.email;
  const firstName = decodeToken["custom:first_name"];
  const lastName = decodeToken["custom:last_name"];

  const handleLogOut = async () => {
    await Auth.signOut();
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/user-profile/",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        localStorage.getItem(
          "profile",
          JSON.stringify(response.data.body.data)
        );
        // window.location.reload(true);
        setUser(response.data.body.data);
        // setFirst_name(response.data.body.data.first_name);
        // setLastName(response.data.body.data.last_name);
        // console.log(user);
      })
      .catch((error) => {
        // console.log(error);
      });
    // window.localStorage.setItem("profile", JSON.stringify(users));
  }, []);
  const [isSubscription, setIsSubscription] = useState(
    localStorage.getItem("isSubscription") === "true"
  );
  useEffect(() => {
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/retrieve-user-subscription",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const productData = response.data.body.data;
        // console.log(productData);
        if (productData?.subscription_active) {
          localStorage.setItem("isSubscription", true);
          setIsSubscription(true);
        } else {
          localStorage.removeItem("isSubscription");
          setIsSubscription(false);
        }
        // setSubscription(response.data.body.data.product.active);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();
    // const return_url = process.env.STRIPE_RETURN_URL;
    // const return_url = "https://d3ouejn2p8m8vy.cloudfront.net/home";
    const return_url = "http://localhost:3000/";
    // console.log(return_url);
    // setIsLoading(true);
    await axios
      .post(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/stripe-customer-portal",
        { return_url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        // console.log(res);
        SetCustomerpotalurl(res.data.body.data?.session_url);
        window.location.replace(res.data.body.data?.session_url);
      });
  };
  return (
    <>
      {/* <div className="container d-md-none d-block pt-3 pb-2">
          <button className="btn px-1 py-0 open-btn">
            <i class="bi bi-list"></i>
          </button>
        </div> */}

      <button className="container btn px-1 py-3 open-btn d-md-none d-block">
        <i class="bi bi-list"></i>
      </button>
      <div className="sidebar" id="side_nav">
        <div className="header-box px-2 py-2">
          <button className="btn d-md-none d-block close-btn px-1 py-0">
            <i class="bi bi-x"></i>
          </button>
          <div className="sidebar-head">
            <div className="sidebar-img">
              <div
                className="rounded-circle profile-img profile-sidebar"
                width="40"
                height="40"
              >
                {LastAuthUser.charAt(0).toUpperCase()}
                {LastAuthUser.charAt(1).toUpperCase()}
              </div>
            </div>
          </div>
          <div className="sidehead-text text-center pb-4">
            {/* <h5 className="mb-0">
              {user.first_name ? user.first_name : ""}{" "}
              {user.last_name ? user.last_name : ""}
            </h5> */}
            <span className="text-lgt">{LastAuthUser}</span>
          </div>
        </div>

        <div className="list-sidebar">
          {/* <NavLink to="/overview" className="text-decoration-none sidebr">
            <i class="bi bi-gear side-icon"></i>Overview
          </NavLink>

          <NavLink to="/profile" className="text-decoration-none sidebr">
            <i class="bi bi-person side-icon"></i>Profile
          </NavLink> */}

          <NavLink
            to="/change-password"
            className="text-decoration-none sidebr"
          >
            <i class="bi bi-lock side-icon"></i>Change Password
          </NavLink>

          {isSubscription ? (
            <NavLink
              to="/subscription"
              className="text-decoration-none sidebr"
              onClick={submitHandler}
            >
              <i class="bi bi-star side-icon"></i>Subscription
            </NavLink>
          ) : (
            <NavLink to="/plan" className="text-decoration-none sidebr">
              <i class="bi bi-star side-icon"></i>Subscription
            </NavLink>
          )}

          {/* <NavLink to="/support" className="text-decoration-none sidebr">
            <i class="bi bi-telephone side-icon"></i>Help
          </NavLink> */}

          <NavLink to="/contact" className="text-decoration-none sidebr">
            <i class="bi bi-telephone side-icon"></i>Contact Us
          </NavLink>
        </div>
      </div>
      {/* <div className="content">
</div> */}
    </>
  );
};

export default SidebarComponent;
