import React, { useState } from "react";

import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const ForgotPassword = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleForgotPassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await Auth.forgotPassword(email).then((value) => {
        navigate("/reset-password", {
          state: {
            email,
          },
        });
        // console.log(value);
      });

      setLoading(false);
    } catch (error) {
      if (error.code === "UserNotFoundException") {
        setError("User not found");
        // console.log("Error: User not found");
        // Display error message to the user
      } else {
        setError(error.message);
      }
      setLoading(false);
      // console.log(error);
    }
  };
  //   const handlePasswordReset = async (e) => {
  //     e.preventDefault();
  //     try {
  //       await Auth.forgotPassword(email).then((value) => {
  //         navigate("/reset-password");
  //         console.log(value);
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  return (
    <>
      <div className="d-flex wrapper ">
        <div className="d-flex flex-center w-lg-50">
          <div className="w-lg-420px p-10 mt-5">
            <a href="/" className="text-dark">
              <img src="assets/popal-lgo.svg" alt="" className="logo-auth" />
            </a>
            <div className="login-txt pt-5">
              <h3>Forgot Password</h3>
              <form className="login-frm pt-2" onSubmit={handleForgotPassword}>
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {error}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setError("")}
                    ></button>
                  </div>
                )}

                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-3 py-2 d-grid gap-2">
                  {!loading && (
                    <button type="submit" className="btn btn-primary">
                      Send Reset Code
                    </button>
                  )}

                  {loading && (
                    <button type="submit" className="btn btn-primary" disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>

                <div className="text-center pb-5">
                  <Link to="/login" className="link-primary px-1">
                    <img src="assets/arrow.png" alt="" />
                    Back to Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"></div>
      </div>
    </>
  );
};

export default ForgotPassword;
