import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import LayoutComponent from "../shared/LayoutComponent";
import moment from "moment";
import { Auth } from "aws-amplify";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
const ContactModal = ({ isFooter }) => {
  const [agree, setAgree] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "AgilemoveEmail",
        "template_25yycbb",
        form.current,
        "WQsAhNtK-rNnMMVLd"
      )
      .then(
        (result) => {
          // console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  const modalClose = () => {
    handleClose();
    toast.success("Email Sent Successful", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const checkboxHandler = () => {
    setAgree(!agree);
  };
  const [modalData, setModalData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {isFooter ? (
        <div
          className="text-drk cursor-pointer"
          id="contact"
          onClick={handleShow}
        >
          Contact Us
        </div>
      ) : (
        <div class="btn btn-primary mt-4" onClick={handleShow}>
          Contact Us
        </div>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter text-center"
        centered
      >
        <Modal.Header className="d-inline p-3">
          <Modal.Title>
            <h4 className="card-title align-items-center text-center pb-1 pt-4">
              <span className="card-label fw-bold fs-3">Contact Us</span>
            </h4>
            <p className="text-center">
              Have any questions? We'd love to hear from you.
            </p>
          </Modal.Title>

          <button
            type="button"
            className="btn-close close-icon1"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </Modal.Header>
        <Modal.Body className="border-none px-4">
          <form ref={form} onSubmit={sendEmail}>
            <div className="profile-form">
              <div className="mb-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  Name*
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  required
                  name="user_name"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="formGroupExampleInput" class="form-label">
                  Email*
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  required
                  name="user_email"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  Subject*
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  required
                  name="subject"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Description*
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  required
                  name="message"
                ></textarea>
              </div>

              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  onChange={checkboxHandler}
                />
                <label class="form-check-label" for="exampleCheck1">
                  I’ve read and accepted the{" "}
                  <a href="/privacy-policy" className="color-primary">
                    {" "}
                    Terms and Privacy Policy
                  </a>
                </label>
              </div>

              <div className="d-grid">
                <button
                  disabled={!agree}
                  type="submit"
                  value="Send"
                  className="btn btn-primary mt-2"
                  onClick={modalClose}
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="border-none"></Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactModal;
