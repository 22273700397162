import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/home";
import ListPage from "../pages/list";
import Login from "../pages/login";
import Signup from "../pages/signup";
import Confirmation from "../pages/confirmation";
import ProtectedRoute from "./ProtectedRoute";
import LandingPage from "../pages/landingpage";
import Profile from "../pages/profile";
import ForgotPassword from "../pages/forgotPassword";
import ResetNewPassword from "../pages/resetNewPassword";
import Account from "../pages/account";
import Plan from "../pages/plan";
import Contact from "../pages/contact";
import Overview from "../pages/overview";
import PrivacyPolicy from "../pages/privacyPolicy";
import Stripe from "../pages/stripe";
import Pricing from "../pages/pricing";
import Plan1 from "../pages/plan1";
import NotFound from "../pages/notFound";
import Help from "../pages/support";
import ViewUserStories from "../pages/viewUserStories";
import UploadJiraAzure from "../pages/uploadJiraAzure";
import Callback from "../pages/callback";
import { getAuthToken } from "../utils/token.utils";

const Routers = () => {
  const token = getAuthToken();
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/home" element={<HomePage />} />
          <Route path="/list" element={<ListPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/change-password" element={<Account />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/subscription" element={<Plan1 />} />
          <Route path="/support" element={<Help />} />
          {/* <Route path="/callback" element={<Callback />} /> */}

          <Route path="/view-user-stories" element={<ViewUserStories />} />
          <Route path="/upload-jira-azure" element={<UploadJiraAzure />} />
        </Route>
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/confirm" element={<Confirmation />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetNewPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/stripe" element={<Stripe />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Routers;
