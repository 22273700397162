import React from "react";
import LayoutComponent from "../shared/LayoutComponent";
import SidebarComponent from "../shared/SidebarComponent";
import Stripe from "./stripe";

const Plan = () => {
  return (
    <>
      {/* Dashboard */}
      <LayoutComponent>
        <div className="main-container d-flex">
          <SidebarComponent></SidebarComponent>
          <div className="content py-5 px-5">
            <div className="container">
              <h4 className="card-title align-items-start text-center flex-column card-label fs-3">
                Subscription Plan
              </h4>
              {/* <span className="card-label fw-bold fs-3 mb-1">
              Change your Password
              </span> */}
              <p className="text-center pb-4">
                You have a free plan right now.
              </p>

              <div className="text-center mb-1 mt-4">
                <h3 className="tex pb-3">Choose your Subscription Plan</h3>
              </div>
              <Stripe></Stripe>
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};

export default Plan;
