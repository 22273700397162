import React, { useState } from "react";
import LayoutComponent from "../shared/LayoutComponent";
import JiraForm from "./JiraForm";
import AzureForm from "./azureForm";
const UploadJiraAzure = () => {
  const [selectedOption, setSelectedOption] = useState("jira");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      <LayoutComponent>
        <div className="main-view">
          <div className="container">
            <div className="mt-5 mb-4">
            <h4 className="card-title align-items-start flex-column mb-4"><span className="card-label fw-bold fs-3 mb-1">Select your Tools</span></h4>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="jira"
                  checked={selectedOption === "jira"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Jira
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="azure"
                  checked={selectedOption === "azure"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Azure
                </label>
              </div>

              {selectedOption === "jira" && <JiraForm />}
              {selectedOption === "azure" && <AzureForm />}
            </div>
          </div>
        </div>
        {/* <FooterComponent /> */}
      </LayoutComponent>
    </>
  );
};

export default UploadJiraAzure;
