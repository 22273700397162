import React, { useState, useEffect } from "react";
import LayoutComponent from "../shared/LayoutComponent";
import SidebarComponent from "../shared/SidebarComponent";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import jwtdecode from "jwt-decode";
import axios from "axios";
import { useAuthStateUpdaterContext } from "../context/UserProvider";
import { getAuthToken } from "../utils/token.utils";

const Profile = () => {
  const { dispatch } = useAuthStateUpdaterContext();
  const token = getAuthToken();

  const decodeToken = jwtdecode(token);

  const LastAuthUser = decodeToken.email;

  async function updateUser() {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:favorite_ice_cream": "vanilla",
    });
  }
  const [user, setUser] = useState("");
  const [first_name, setFirst_name] = useState(user);
  const [last_name, setLastName] = useState(user);
  const [country, setCountry] = useState();
  const [phone, setPhone] = useState();
  const [company, setCompany] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const handleProfileUpdate = (e) => {
    setLoading(true);

    const params = JSON.stringify({
      first_name: first_name,
      last_name: last_name,
      company: company,
      country: country,
      phone: phone,
    });

    axios
      .post(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/user-profile/",
        params,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          first_name: first_name,
          last_name: last_name,
          company: company,
          country: country,
          phone: phone,
        });

        // localStorage.setItem("profile", JSON.stringify(res));
        // window.location.reload(true);
        // console.log("firstname", res.data.body.data.first_name);
        setLoading(false);
        toast.success("Profile Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/user-profile/",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        localStorage.getItem(
          "profile",
          JSON.stringify(response.data.body.data)
        );
        setUser(response.data.body.data);
        setFirst_name(response.data.body.data.first_name);
        setLastName(response.data.body.data.last_name);
        setPhone(response.data.body.data.phone);
        setCountry(response.data.body.data.country);
        setCompany(response.data.body.data.company);
      })
      .catch((error) => {});
    // window.localStorage.setItem("profile", JSON.stringify(users));
  }, []);

  // const handleChange = (user) => {
  //   setFirst_name(user.first_name);
  // };
  // console.log(handleChange);

  return (
    <>
      {/* Dashboard */}
      <LayoutComponent>
        <div className="main-container d-flex">
          <SidebarComponent></SidebarComponent>
          <div className="content py-5 px-5">
            <div className="container">
              <h4 className="card-title align-items-start flex-column pb-4">
                <span className="card-label fw-bold fs-3 mb-1">
                  Update your profile
                </span>
              </h4>
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              )}
              <div className="profile-form w-75 w-100-mob">
                <div class="row">
                  <div class="col-md-6 pb-4">
                    <label for="formGroupExampleInput" class="form-label">
                      First Name*
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={first_name}
                      placeholder="First Name"
                      name="first_name"
                      {...register("first_name", {
                        onChange: (e) => setFirst_name(e.target.value),
                      })}
                    />
                    {errors.first_name && (
                      <small className="text-danger">
                        {errors.first_name.message}
                      </small>
                    )}
                  </div>
                  <div class="col-md-6 pb-4">
                    <label for="formGroupExampleInput" class="form-label">
                      Last Name*
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      value={last_name}
                      name="last_name"
                      {...register("last_name", {
                        onChange: (e) => setLastName(e.target.value),
                      })}
                    />
                    {errors.last_name && (
                      <small className="text-danger">
                        {errors.last_name.message}
                      </small>
                    )}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 pb-4">
                    <label for="formGroupExampleInput" class="form-label">
                      Country Name*
                    </label>
                    <input
                      type="name"
                      class="form-control"
                      placeholder="Country Name"
                      name="country"
                      value={country}
                      {...register("country", {
                        onChange: (e) => setCountry(e.target.value),
                      })}
                    />
                    {errors.country && (
                      <small className="text-danger">
                        {errors.country.message}
                      </small>
                    )}
                  </div>
                  <div class="col-md-6 pb-4">
                    <label for="formGroupExampleInput" class="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Company Name"
                      name="company"
                      value={company}
                      {...register("company", {
                        onChange: (e) => setCompany(e.target.value),
                      })}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col pb-4">
                    <label for="formGroupExampleInput" class="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Phone Number"
                      value={phone}
                      name="phone"
                      {...register("phone", {
                        // pattern: {
                        //   value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                        //   message: "Please enter a Valid number",
                        // },
                        onChange: (e) => setPhone(e.target.value),
                      })}
                    />
                    {errors.phone && (
                      <small className="text-danger">
                        {errors.phone.message}
                      </small>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-primary mt-4"
                  onClick={handleSubmit(handleProfileUpdate)}
                >
                  {loading && <i className="fas fa-spinner fa-spin"></i>}
                  {loading && <span> Save Changes</span>}
                  {!loading && <span> Save Changes</span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};

export default Profile;
