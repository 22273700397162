import React, { useState, useEffect } from "react";
import LayoutComponent from "../shared/LayoutComponent";
import axios from "axios";
import LoadingSpinner from "./spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import FooterComponent from "../shared/FooterComponent";
import { getAuthToken } from "../utils/token.utils";

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [customerpotalurl, SetCustomerpotalurl] = useState("");
  const token = getAuthToken();

  const navigateToPortal = async (event) => {
    // event.preventDefault();
    // const return_url = process.env.STRIPE_RETURN_URL;
    // const return_url = "https://d3ouejn2p8m8vy.cloudfront.net/home";
    const return_url = "http://localhost:3000/";
    // console.log(return_url);
    // setIsLoading(true);
    await axios
      .post(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/stripe-customer-portal",
        { return_url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        // console.log(res);
        SetCustomerpotalurl(res.data.body.data?.session_url);

        window.location.replace(res.data.body.data?.session_url);
      });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const description = event.target.description.value;
    setIsLoading(true);
    await axios
      .post(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/create-user-stories",
        { description },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        const error = res.data.body.message;
        const checkSubscription = res.data.body.data.is_subscribed;
        if (res?.data?.body?.success === false) {
          swal({
            title: "Trial Expired",
            text: `${error}`,
            icon: "warning",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#088AB2",
            confirmButtonText: "Yes",
            closeOnConfirm: false,

            dangerMode: true,
          }).then((gotoLogin) => {
            if (checkSubscription) {
              navigateToPortal();
            } else {
              navigate("/plan");
            }
          });
        }
        navigate("/list");
        setIsLoading(false);
        // if (res.data.body.data.generating === true) {
        //   setInterval(() => {
        //     window.location.reload(true);
        //   }, 30000);
        // }
        // toast.info(res.data.body.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 120000,
        // });
      })
      .catch((error) => {
        console.log(error);
        if (error.request.status === 0) {
          // toast.error(error.response.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 3000,
          // });
          swal({
            title: "Session Expired",
            text: "Your session has expired. Would you like to be redirected to the login page?",
            icon: "warning",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#088AB2",
            confirmButtonText: "Yes",
            closeOnConfirm: false,

            dangerMode: true,
          }).then((gotoLogin) => {
            if (gotoLogin) {
              localStorage.clear();

              navigate("/login");
            }
          });
          // setError(error.response.data.message);
          // localStorage.clear();
          // navigate("/login");
        } else if (error.response.status === 400) {
          // toast.error(error.response.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 3000,
          // });
          swal({
            title: "Trial Expired",
            text: error.response.data.message,
            icon: "warning",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#088AB2",
            confirmButtonText: "Yes",
            closeOnConfirm: false,

            dangerMode: true,
          }).then((gotoLogin) => {
            if (gotoLogin) {
              localStorage.clear();

              navigate("/login");
            }
          });
          // setError(error.response.data.message);
          // localStorage.clear();
          // navigate("/login");
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // console.log(data);

  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://gzv6bp5l0l.execute-api.us-east-1.amazonaws.com/dev/api/retrieve-user-subscription",
  //       {
  //         headers: {
  //           "content-type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response.data.body.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   // window.localStorage.setItem("profile", JSON.stringify(users));
  // }, []);
  return (
    <LayoutComponent>
      <div className="container requirement-pg pt-5">
        <div className="text-start">
          <div className="header-title mt-4">
            <h2 className="wv-heading--title">Requirement Description</h2>
            {/* <p className="wv-heading--subtitle">
              Send your task description to GPT - 3 for user stories and
              acceptance criteria generation.
            </p> */}
          </div>
        </div>

        <div className="mx-aut">
          <div className="myform form pb-5 ">
            <form className="signupForm" onSubmit={submitHandler}>
              <div className="panel panel-default"></div>
              {/* <div className="form-group">
<label htmlFor="name">Requirement Name</label>
<input type="name" required className="form-control" name="name"
placeholder="Name"Enter your requirement description
/>
</div> */}
              <div className="form-group mt-2 text-start">
                <label htmlFor="name">
                  Enter your requirement description. Based on your requirement
                  one or multiple user stories will be generated.{" "}
                </label>
                <textarea
                  id="description"
                  className="form-control mt-4"
                  name="description"
                  rows="14"
                  required
                  placeholder="Enter the description of your requirement here..."
                />
              </div>
              <div className="form-group mt-4 text-end">
                <a href="/list">
                  <button
                    type="submit"
                    className="btn btn-primary g-button mx-2"
                  >
                    {isLoading && <i className="fas fa-spinner fa-spin"></i>}
                    {isLoading && <span>Generating</span>}
                    {!isLoading && <span>Generate User Stories</span>}
                  </button>
                  <button type="reset" className="btn btn-outline">
                    Clear
                  </button>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterComponent />
    </LayoutComponent>
  );

  // return (
  //   <>
  //     <div className="App">{isLoading ? <LoadingSpinner /> : renderUser}</div>
  //   </>
  // );
};

export default HomePage;
