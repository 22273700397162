import logo from "./logo.svg";
import "./App.css";
import Routers from "./routers";
import "./Styles/style.scss";
import "./Styles/pagination.scss";
import "./Styles/sidebar.scss";
import "./Styles/space.scss";
import "./Styles/variable.scss";
import "./Styles/pricing.scss";
import LoadingSpinner from "./pages/spinner";
import { Amplify } from "aws-amplify";
import awsExports from "./configs/aws";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/UserProvider";

function App() {
  Amplify.configure(awsExports);
  return (
    <>
      <AuthProvider>
        <Routers />
        <ToastContainer />
      </AuthProvider>
    </>
  );
}

export default App;
