import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import jwtdecode from "jwt-decode";
import { Link } from "react-router-dom";
import axios from "axios";
import { getAuthToken } from "../utils/token.utils";

import {
  useAuthStateContext,
  useAuthStateUpdaterContext,
} from "../context/UserProvider";
const NavbarComponent = () => {
  const { state: authUser } = useAuthStateContext();
  const { dispatch } = useAuthStateUpdaterContext();
  const token = getAuthToken();

  let navigate = useNavigate();
  const [user, setUser] = useState("");
  const [suscription, setsuscription] = useState("");
  const [customerpotalurl, SetCustomerpotalurl] = useState("");

  const decodeToken = jwtdecode(token);

  const LastAuthUser = decodeToken.email;
  const firstName = decodeToken["custom:first_name"];
  const lastName = decodeToken["custom:last_name"];

  const handleLogOut = async () => {
    localStorage.clear();
    await Auth.signOut();
    navigate("/login");
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("updatedUser"));
    if (user) {
      dispatch(user);
    }
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/user-profile/",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        localStorage.getItem(
          "profile",
          JSON.stringify(response.data.body.data)
        );
        // window.location.reload(true);
        setUser(response.data.body.data);
        // setFirst_name(response.data.body.data.first_name);
        // setLastName(response.data.body.data.last_name);
        // console.log(user);
      })
      .catch((error) => {});
    // window.localStorage.setItem("profile", JSON.stringify(users));
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/retrieve-user-subscription",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data.body.data);
        setsuscription(response.data.body.data);
      })
      .catch((error) => {});
    // window.localStorage.setItem("profile", JSON.stringify(users));
  }, []);
  const [isSubscription, setIsSubscription] = useState(
    localStorage.getItem("isSubscription") === "true"
  );
  useEffect(() => {
    axios
      .get(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/retrieve-user-subscription",
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const productData = response.data.body.data;
        // console.log(productData);
        if (productData?.subscription_active) {
          localStorage.setItem("isSubscription", true);
          setIsSubscription(true);
        } else {
          localStorage.removeItem("isSubscription");
          setIsSubscription(false);
        }
        // setSubscription(response.data.body.data.product.active);
      })
      .catch((error) => {});
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();
    // const return_url = process.env.STRIPE_RETURN_URL;
    // const return_url = "https://d3ouejn2p8m8vy.cloudfront.net/home";
    const return_url = "http://localhost:3000/";
    // setIsLoading(true);
    await axios
      .post(
        "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/stripe-customer-portal",
        { return_url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        SetCustomerpotalurl(res.data.body.data?.session_url);
        window.location.replace(res.data.body.data?.session_url);
      });
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="navbar-light bg-white border-bottom"
      sticky="top"
    >
      <div className="container">
        {token ? (
          <Navbar.Brand className="navbar-brand lgo" href="/home">
            {" "}
            <img src="assets/popal-lgo.svg" alt="" className="logo-home" />
          </Navbar.Brand>
        ) : (
          <Navbar.Brand className="navbar-brand lgo" href="/home">
            {" "}
            <img src="assets/popal-lgo.svg" alt="" className="logo-home" />
          </Navbar.Brand>
        )}
        {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="menus me-auto">
            <NavLink to="/home" className="space-nav">
              Home
            </NavLink>
            <NavLink to="/list">Request History</NavLink>
            {/* <Nav.Link href="/home">Home</Nav.Link>
          <Nav.Link href="/list">Request History</Nav.Link> */}
            {/* <Nav.Link exact activeClassName="menus_active"> <Link to ="/home" className="active">Home</Link></Nav.Link>
          <Nav.Link exact activeClassName="menus_active" href="/list"> <Link to ="/list">Request History</Link></Nav.Link> */}
            {/* <Nav.Link href="/list">Request History</Nav.Link> */}

            {/* <li className="nav-item active">
              <Link to="/home" activeStyle={{ color: "blue" }} className="nav-link">
                Home <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item active">
              <Link to="/list" className="nav-link">
                Request History <span className="sr-only">(current)</span>
              </Link>
            </li> */}
          </Nav>

          {/* <div className="col-span-2 lg:hidden flex justify-items-stretch	 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#667085"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
        <div className="col-span-2 lg:hidden flex justify-items-stretch	 items-center px-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#667085"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
        </div>
          <button  className="btn btn-md btn-primary flex 	 items-center" onClick={handleLogOut}>Logout
          </button> */}

          {/* Start::Dropdown  */}
          {/* {token && LastAuthUser} */}
          <Nav>
            <NavDropdown
              className="rounded-circle profile-img text-upper"
              title={LastAuthUser.charAt(0).toUpperCase()}
              width="40"
              height="40"
            >
              {/* <div className="rounded-circle profile-img" width="40"height="40">
                    {firstName.charAt(0)}
                    {lastName.charAt(0)}
                  </div> */}

              <NavDropdown.Item>
                <div className="row dropdown-row">
                  <div className="col-2 align-items-center d-inline-grid">
                    <div
                      className="rounded-circle profile-img1"
                      width="40"
                      height="40"
                    >
                      {LastAuthUser.charAt(0).toUpperCase()}
                      {LastAuthUser.charAt(1).toUpperCase()}
                    </div>
                  </div>
                  <div className="col-10 col-name">
                    <div>
                      <b>
                        {" "}
                        {}
                        {authUser?.authUser?.first_name
                          ? authUser?.authUser?.first_name
                          : user.first_name}{" "}
                        {authUser?.authUser?.last_name
                          ? authUser?.authUser?.last_name
                          : user.last_name}
                      </b>
                    </div>
                    <div
                      className="toltip"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={token && LastAuthUser}
                    >
                      {token && LastAuthUser}
                    </div>
                  </div>
                </div>
              </NavDropdown.Item>
              <hr />
              {/* <span className="dropdown-item border-bottom ">
                    {token && LastAuthUser}
                  </span> */}
              {/* <NavDropdown.Item>
                <Link to="/overview" className="dropdown-item">
                  Overview
                </Link>
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item>
                <Link to="/profile" className="dropdown-item">
                  Profile{" "}
                </Link>{" "}
              </NavDropdown.Item> */}
              <NavDropdown.Item>
                <Link to="/change-password" className="dropdown-item">
                  Change Password{" "}
                </Link>{" "}
              </NavDropdown.Item>
              {isSubscription ? (
                <NavDropdown.Item>
                  <Link className="dropdown-item" onClick={submitHandler}>
                    {/* <a className="dropdown-item" onClick={submitHandler}> */}
                    Subscription
                    {/* </a> */}
                  </Link>
                </NavDropdown.Item>
              ) : (
                <NavDropdown.Item>
                  <Link to="/plan" className="dropdown-item">
                    Subscription
                  </Link>
                </NavDropdown.Item>
              )}

              <NavDropdown.Item>
                <Link to="/support" className="dropdown-item">
                  Help
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/contact" className="dropdown-item">
                  Contact Us
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/login"
                  className="dropdown-item"
                  onClick={handleLogOut}
                >
                  Log Out
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          {/* End::Dropdowm */}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavbarComponent;
