import React, { useState, useEffect } from "react";
import LayoutComponent from "../shared/LayoutComponent";
import moment from "moment";
import swal from "sweetalert";
import LoadingSpinner from "./spinner";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import ReactPaginate from "react-paginate";
import FooterComponent from "../shared/FooterComponent";
import { Link } from "react-router-dom";
import {
  getStoriesDescripton,
  listUserStories,
} from "../services/stories/stories.service";

const ListPage = () => {
  const FETCH_INTERVAL = 60000; // in ms
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [show, setShow] = useState(false);
  const [postsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [generating, setGenerating] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    setIsLoading(true);
    getUserStories();
  }, []);

  useEffect(() => {
    const isGenerating = data.find((item) => item.generating);
    if (isGenerating) {
      setTimeout(() => {
        getUserStories();
      }, FETCH_INTERVAL);
    }
  }, [data]);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const getUserStories = () => {
    listUserStories()
      .then((res) => {
        const responseData = res?.data?.body?.data;
        if (responseData) {
          setData(responseData);
          setIsLoading(false);
          setIsPending(true);
          setGenerating(true);
        }
      })
      .catch((error) => {
        if (error.request.status === 0) {
          swal({
            title: "Session Expired",
            text: "Your session has expired. Would you like to be redirected to the login page?",
            icon: "warning",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#088AB2",
            confirmButtonText: "Yes",
            closeOnConfirm: false,
            dangerMode: true,
          }).then((gotToLogin) => {
            if (gotToLogin) {
              localStorage.clear();
              navigate("/login");
            }
          });
        }
      });
  };

  const showDescription = (title) => {
    getStoriesDescripton({ title }).then((res) => {
      const response = res?.data?.body?.data;
      if (response) {
        setModalData(response);
        handleShow();
      }
    });
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <LayoutComponent>
          <div className="table-content pt-5">
            <div className="container">
              <div className="table-header pb-3">
                <h4 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    Request History
                  </span>
                </h4>
                <div className="card-toolbar">
                  <Link to="/home" className="btn btn-sm btn-primary">
                    <span className="svg-icon svg-icon-2"></span>{" "}
                    <i className="fa fa-plus plus" aria-hidden="true"></i>
                    New Request
                  </Link>
                </div>
              </div>

              {/* start table */}
              <div class="table-responsive">
                <table className="table">
                  <thead className="table-light">
                    <tr>
                      <th className="min-w-30px" scope="col">
                        Date
                      </th>
                      <th className="min-w-30px" scope="col">
                        Time
                      </th>
                      <th className="w-420px" scope="col">
                        Request
                      </th>
                      {/* <th className="min-w-190px" scope="col">
                  Progress
                </th> */}
                      <th className="min-w-30px text-center" scope="col">
                        Status
                      </th>
                      <th className="min-w-30px text-center" scope="col">
                        User Stories (CSV file)
                      </th>
                      {/* <th className="min-w-30px" scope="col"></th>
                      <th className="min-w-30px" scope="col"></th> */}
                    </tr>
                  </thead>
                  {currentPosts.map((item) => (
                    <tbody>
                      <tr
                        style={{
                          background:
                            item.generating === true ? "#faf6eb" : item.color,
                        }}
                      >
                        <td>{moment(item.date_time).format("M/D/YYYY")}</td>
                        <td>{moment(item.date_time).format("ddd h:mma")}</td>
                        <td
                          className="overflow-txt"
                          onClick={(e) => {
                            showDescription(item.title);
                          }}
                        >
                          {item.title}
                        </td>

                        <td className="text-center">
                          {item.generating === false ? (
                            <span className="badge badge-light-success fs-7 fw-semibold">
                              Completed
                            </span>
                          ) : (
                            <span className="badge bg-info fs-7 fw-semibold">
                              Generating...
                            </span>
                          )}
                        </td>
                        {isPending && item.generating === false ? (
                          <td className="text-center">
                            <Link
                              to={`/view-user-stories`}
                              state={{ title: item.title }}
                              className="dwn color-primary"
                            >
                              View <i class="bi bi-eye"></i>
                            </Link>
                          </td>
                        ) : (
                          <td className="text-center">
                            <Link
                              to={`/${item.title}`}
                              className="dwn color-primary"
                            >
                              {" "}
                            </Link>
                          </td>
                        )}

                        {/* Download Item */}
                        {/* {isPending && item.generating === false ? (
                          <td className="text-center">
                            <a
                              href={item.download_url}
                              className="dwn color-primary"
                            >
                              Download <i class="bi bi-download"></i>
                            </a>
                          </td>
                        ) : (
                          <td className="text-center">
                            <a
                              href={item.download_url}
                              className="dwn color-primary"
                            >
                              {" "}
                            </a>
                          </td>
                        )} */}
                        {/* Download Item:: END */}

                        {/* <td className="text-center">
                          <a href="/" className="viw icon-color">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                          </a>
                          <a href="/" className="icon-color">
                            <i
                              className="fa fa-thumbs-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </td> */}

                        {/* <td className="text-end">
                          <CommentPage></CommentPage>
                        </td> */}
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>

              <div className="table-header pt-3 pb-3">
                <p className="text-lgt align-items-start flex-column">
                  {/* <span className="card-label mb-1">
                Showing 1 to 10 of 50 entries
              </span> */}
                </p>

                {data ? (
                  <div className="card-toolbar">
                    {/* ... */}
                    <ReactPaginate
                      onPageChange={paginate}
                      pageCount={Math.ceil(data.length / postsPerPage)}
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      containerClassName={"pagination"}
                      pageLinkClassName={"page-number"}
                      previousLinkClassName={"page-number"}
                      nextLinkClassName={"page-number"}
                      activeLinkClassName={"active"}
                    />
                  </div>
                ) : (
                  <div className="loading">Loading..</div>
                )}
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter text-center"
            centered
          >
            <Modal.Header className="pt-5 px-5">
              <Modal.Title>{modalData.title}</Modal.Title>

              <button
                type="button"
                className="btn-close close-icon"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </Modal.Header>
            <Modal.Body className="px-5">{modalData.description}</Modal.Body>
            <Modal.Footer className="px-5">
              {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
              <div variant="btn btn-sm btn-primary" onClick={handleClose}>
                <a
                  href={modalData.download_url}
                  className="btn btn-sm btn-primary"
                >
                  Download CSV <i class="bi bi-download"></i>
                </a>
              </div>
            </Modal.Footer>
          </Modal>
          <FooterComponent />
        </LayoutComponent>
      )}
    </div>
  );
};

export default ListPage;
