import axios from "axios";
import { getAuthToken } from "../../utils/token.utils";

const listUserStories = () => {
  const token = getAuthToken();
  return axios.get(
    "https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/list-user-stories",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getStoriesDescripton = ({ title }) => {
  const token = getAuthToken();
  return axios.get(
    `https://1jbi2dgyhi.execute-api.us-east-2.amazonaws.com/prod/api/list-user-stories/?title=Blog`,
    {
      params: { title: title },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export { listUserStories, getStoriesDescripton };
