// const awsExports = {
//   Auth: {
//       'userPoolId': 'us-east-1_0JUFgS5bq',
//       'userPoolWebClientId': '749lb0frtagskai6tvklqluhfn'
//   },
//   oauth: {
//       domain: "jirastorycreator.auth.us-east-1.amazoncognito.com",
//       redirectSignIn: "http://localhost:3000/home",
//       redirectSignUp: "http://localhost:3000/",
//       redirectSignOut: "http://localhost:3000/",
//       responseType: "token"
//   }
// }

//   export default awsExports

const awsExports = {
  Auth: {
  userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  oauth: {
  domain:process.env.REACT_APP_OAUTH_DOMAIN,
  redirectSignIn:process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
  redirectSignUp:process.env.REACT_APP_OAUTH_REDIRECT_SIGNUP,
  redirectSignOut:process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
  responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE,
  }

};

export default awsExports