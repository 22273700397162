import React from "react";


const FooterComponent = ( ) => {
    return (
      <footer className="footer border-top p-4">
      <div className="container">
        <div className="copyright-txt">
          <span>Copyright 2023 AgileMove Inc. All Rights Reserved.</span>
          <div className="text-end ">
          <a href="/privacy-policy" className="text-drk me-4">
            Privacy Policy
          </a>
          <a href="/contact" className="text-drk text">
            Contact Us
          </a>
          </div>
        </div>
      </div>
    </footer>

    )
}


export default FooterComponent